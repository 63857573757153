import CryptoJS from 'crypto-js'
import dlv from 'dlv'

export const genHash = ({ input, config }) => {
  const { property, key } = config
  const value = dlv(input, property)
  const hash = CryptoJS.HmacSHA256(value, key).toString(CryptoJS.enc.Hex)
  return hash + Date.now()
}

export default {
  genHash
}
