<template>
  <div class="am-fp-planned-purchase">
    <div>Geplanter Neukauf</div>
    <amui-select
      v-model="formData.period"
      :options="periodOptions"
      :label="formData.period ? null : 'Bitte auswählen'"
      aria-label="Bitte auswählen"
      :invalid="invalid.period"
      viewport="s"
      @change="onChangePeriod"
    >
    </amui-select>
  </div>
</template>
<script>
import { AmuiSelect } from '@/../ui/components/select'

export default {
  components: {
    AmuiSelect
  },
  props: {
    period: {
      type: String,
      required: false,
      default: null
    },
    periodOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      formData: {
        period: this.period
      },
      invalid: {
        period: false
      }
    }
  },
  watch: {
    period(value) {
      this.formData.period = value
    }
  },
  methods: {
    isValid() {
      this.invalid.period =
        this.formData.period === null ||
        !this.periodOptions.map(o => o.value).includes(this.formData.period)

      return !Object.keys(this.invalid).some(key => this.invalid[key])
    },
    validate() {
      return {
        valid: this.isValid()
      }
    },
    onChangePeriod(value) {
      this.invalid.period = false
      this.$emit('update:period', value)
    }
  }
}
</script>
