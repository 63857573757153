<template>
  <div class="am-vehicle-model-preview">
    <div ref="imageContainer" class="am-vehicle-model-preview__image">
      <amui-image
        :sources="imageSources"
        :fallback-source="imageFallbackSource"
        :alt="imageAlt"
      />
    </div>
    <amui-headline
      v-if="title"
      :text="title"
      html-tag="h1"
      looks-like="h5"
    ></amui-headline>
    <div v-if="title" class="am-vehicle-model-preview__emissions">
      {{ wltpPhrase }}
    </div>
  </div>
</template>
<script>
import { AmuiHeadline } from '../../../../ui/components/headline'
import { AmuiImage } from '../../../../ui/components/image'

export default {
  components: {
    AmuiHeadline,
    AmuiImage
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    imageSources: {
      type: Array,
      required: false,
      default: () => []
    },
    imageFallbackSource: {
      type: String,
      required: false,
      default: null
    },
    imageAlt: {
      type: String,
      required: false,
      default: null
    },
    modelWltpMeasurements: {
      type: Array,
      required: true
    },
    modelHybridType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resizeObserver: null
    }
  },
  computed: {
    wltpPhrase() {
      let replacements = {}

      const getMeasurementString = measurement => {
        let retString = 'n/a'
        const data = this.modelWltpMeasurements.find(
          d => d.measurement === measurement
        )

        if (data) {
          if (data.min === data.max) {
            retString = data.min
          } else {
            retString = data.min + ' - ' + data.max
          }

          retString += ' ' + data.unit
        }

        return retString
      }

      if (
        this.modelHybridType === 'NOHY_NO_HYBRID' ||
        this.modelHybridType === 'MHEV_MILD_HYBRID'
      ) {
        replacements = {
          fuelConsumptionCombined: getMeasurementString(
            'fuelConsumption.combined'
          ),
          co2Combined: getMeasurementString('co2.combined')
        }
      } else if (this.modelHybridType === 'BEV_FULL_ELECTRIC') {
        replacements = {
          electricConsumptionCombined: getMeasurementString(
            'electricConsumption.combined'
          ),
          pureElectricRangeCombined: getMeasurementString(
            'pureElectricRange.combined'
          ),
          pureElectricRangeCity: getMeasurementString('pureElectricRange.city')
        }
      } else if (this.modelHybridType === 'PHEV_PLUGIN_HYBRID') {
        replacements = {
          fuelConsumptionWeighted: getMeasurementString(
            'fuelConsumption.weighted'
          ),
          co2Weighted: getMeasurementString('co2.weighted'),
          equivalentAllElectricRangeCombined: getMeasurementString(
            'equivalentAllElectricRange.combined'
          ),
          equivalentAllElectricRangeCity: getMeasurementString(
            'equivalentAllElectricRange.city'
          )
        }
      }

      return this.$t('model.legal.wltp.' + this.modelHybridType, replacements)
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(entries => {
      if (entries.length) {
        this.$emit('update:image-width', entries[0].contentRect.width)
      }
    })

    this.resizeObserver.observe(this.$refs.imageContainer)
  },

  beforeDestroy() {
    this.resizeObserver && this.resizeObserver.disconnect()
  }
}
</script>
