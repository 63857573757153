<template>
  <div class="am-fp-legal-confirmations">
    <amui-checkbox
      v-model="formData.hasConfirmedContact"
      :invalid="invalid.hasConfirmedContact"
      viewport="s"
      @change="onChangeHasConfirmedContact"
      ><span slot="label"
        >Ich bestätige die Einwilligung über eine Kontaktaufnahme</span
      ></amui-checkbox
    >
    <amui-checkbox
      v-model="formData.hasConfirmedTerms"
      :invalid="invalid.hasConfirmedTerms"
      viewport="s"
      @change="onChangeHasConfirmedTerms"
    >
      <amui-i18n
        slot="label"
        :term="
          termsAndConditionsUrl
            ? 'Ich habe die {terms} und {privacy} gelesen und stimme diesen zu.'
            : 'Ich habe die {privacy} gelesen und stimme diesen zu.'
        "
        tag="span"
      >
        <a
          slot="terms"
          :href="termsAndConditionsUrl"
          target="_blank"
          rel="nofollow noopener"
          >AGBs</a
        >
        <a
          slot="privacy"
          :href="privacyPolicyUrl"
          target="_blank"
          rel="nofollow noopener"
          >Datenschutzrichtlinien</a
        >
      </amui-i18n>
    </amui-checkbox>
  </div>
</template>
<script>
import { AmuiI18n } from '@/../ui/components/i18n'
import { AmuiCheckbox } from '@/../ui/components/checkbox'

export default {
  components: {
    AmuiI18n,
    AmuiCheckbox
  },
  props: {
    hasConfirmedContact: {
      type: Boolean,
      required: false,
      default: false
    },
    hasConfirmedTerms: {
      type: Boolean,
      required: false,
      default: false
    },
    termsAndConditionsUrl: {
      type: String,
      required: false,
      default: null
    },
    privacyPolicyUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      formData: {
        hasConfirmedContact: false,
        hasConfirmedTerms: false
      },
      invalid: {
        hasConfirmedContact: false,
        hasConfirmedTerms: false
      }
    }
  },
  watch: {
    hasConfirmedContact(value) {
      this.formData.hasConfirmedContact = value
    },
    hasConfirmedTerms(value) {
      this.formData.hasConfirmedTerms = value
    }
  },
  methods: {
    onChangeHasConfirmedContact(value) {
      this.invalid.hasConfirmedContact = false
      this.$emit('update:hasConfirmedContact', value)
    },
    onChangeHasConfirmedTerms(value) {
      this.invalid.hasConfirmedTerms = false
      this.$emit('update:hasConfirmedTerms', value)
    },
    isValid() {
      this.invalid.hasConfirmedContact = !this.formData.hasConfirmedContact
      this.invalid.hasConfirmedTerms = !this.formData.hasConfirmedTerms

      return !Object.keys(this.invalid).some(key => this.invalid[key])
    },
    validate() {
      return {
        valid: this.isValid()
      }
    }
  }
}
</script>
