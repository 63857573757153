<template>
  <div class="am-fp-dealer-location">
    <div>{{ title }}</div>

    <div>
      <div class="am-fp-dealer-location__location">
        <div>
          <div
            class="am-fp-dealer-location__ac-input-wrapper"
            v-click-outside="closeAutocompletion"
          >
            <amui-input
              :value="address"
              label="Standort-Suche"
              viewport="s"
              autocomplete="off"
              @input="onAddressInput"
              @focus="onAddressFocus"
            />
            <div
              class="am-fp-dealer-location__ac-overlay"
              v-if="hasActiveAutocompletion && addressSuggestions.length"
            >
              <ul>
                <li
                  v-for="(suggestion, index) in addressSuggestions"
                  :key="suggestion.place_id + '-' + index"
                  @click="() => setLocationByPlaceId(suggestion.placeId)"
                >
                  <div>{{ suggestion.primaryLabel }}</div>
                  <div>{{ suggestion.secondaryLabel }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <amui-icon name="crosshairs" @click.native="onDetectLocation" />
        </div>
      </div>
    </div>
    <div>
      <amui-select
        v-model="formData.dealerId"
        :options="dealerOptions"
        label="Filiale wählen"
        aria-label="Filiale wählen"
        :invalid="invalid.dealerId"
        viewport="s"
        @change="onChangeDealerId"
      >
      </amui-select>
    </div>
  </div>
</template>
<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiInput } from '@/../ui/components/input'
import { AmuiSelect } from '@/../ui/components/select'
import { mapState } from 'vuex'
import ClickOutside from 'vue-click-outside'

const debounce = (func, wait) => {
  let timer = 0
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(() => func.apply(this, args), wait)
  }
}

export default {
  components: {
    AmuiIcon,
    AmuiSelect,
    AmuiInput
  },
  directives: {
    ClickOutside
  },
  props: {
    dealerId: {
      type: String,
      required: false,
      default: null
    },
    dealers: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formData: {
        dealerId: this.dealerId
      },
      invalid: {
        dealerId: false
      },
      location: null,
      /*
      location: {
        address: string,
        coordinates: {
          latitude: number,
          longitude: number
        }
      },
      */
      address: '',
      addressSuggestions: [],
      hasActiveAutocompletion: false
    }
  },
  computed: {
    ...mapState('core', {
      googleMapsApiKey: state => state.googleApiKey
    }),
    dealerOptions() {
      let dealers = this.dealers

      if (this.location !== null) {
        const haversine = (lat1, lon1, lat2, lon2) => {
          const r = 6371 // earth radius in km
          const dLat = (lat2 - lat1) * (Math.PI / 180)
          const dLon = (lon2 - lon1) * (Math.PI / 180)
          const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
              Math.cos(lat2 * (Math.PI / 180)) *
              Math.sin(dLon / 2) *
              Math.sin(dLon / 2)
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
          const distance = r * c

          return distance
        }

        const coordinates = this.location.coordinates

        dealers = this.dealers.toSorted((a, b) => {
          const distA = haversine(
            coordinates.latitude,
            coordinates.longitude,
            a.coordinates.latitude,
            a.coordinates.longitude
          )
          const distB = haversine(
            coordinates.latitude,
            coordinates.longitude,
            b.coordinates.latitude,
            b.coordinates.longitude
          )
          return distA - distB
        })
      }

      return dealers.map(dealer => ({
        label: dealer.label,
        value: dealer.value
      }))
    }
  },
  watch: {
    dealerId(value) {
      this.formData.dealerId = value
    },
    location(value) {
      if (value !== null) {
        this.address = value.address
        this.closeAutocompletion()

        if (this.dealerOptions.length) {
          this.onChangeDealerId(this.dealerOptions[0].value)
        }
      }
    }
  },
  methods: {
    openAutocompletion() {
      this.hasActiveAutocompletion = true
    },
    closeAutocompletion() {
      this.hasActiveAutocompletion = false
    },
    isValid() {
      this.invalid.dealerId =
        this.formData.dealerId === null ||
        !this.dealerOptions.map(o => o.value).includes(this.formData.dealerId)

      return !Object.keys(this.invalid).some(key => this.invalid[key])
    },
    validate() {
      return {
        valid: this.isValid()
      }
    },
    onDetectLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async position => {
            this.setLocationByCoordinates(
              position.coords.latitude,
              position.coords.longitude
            )
          },
          error => {
            console.error('Error detecting location:', error.message)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    },
    onAddressFocus() {
      if (this.address.length > 0) {
        this.openAutocompletion()
      }
    },
    onAddressInput: debounce(function(value) {
      if (value.length > 0) {
        this.openAutocompletion()
      } else {
        this.closeAutocompletion()
      }

      this.address = value
      this.setAddressSuggestionsByAddressInput(value)
    }, 300),
    setAddressSuggestionsByAddressInput(input) {
      // eslint-disable-next-line
      var autocompleteService = new google.maps.places.AutocompleteService()

      autocompleteService.getPlacePredictions(
        { input, componentRestrictions: { country: 'de' } },
        (results, status) => {
          // eslint-disable-next-line
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.addressSuggestions = results.map(result => ({
              placeId: result.place_id,
              primaryLabel: result.structured_formatting.main_text,
              secondaryLabel: result.structured_formatting.secondary_text
            }))
          }
        }
      )
    },
    setLocationByCoordinates(latitude, longitude) {
      // eslint-disable-next-line
      const geocoder = new google.maps.Geocoder()

      geocoder.geocode(
        {
          // eslint-disable-next-line
          location: new google.maps.LatLng(latitude, longitude)
        },
        (results, status) => {
          if (
            // eslint-disable-next-line
            status === google.maps.places.PlacesServiceStatus.OK &&
            results.length
          ) {
            this.location = {
              address: results[0].formatted_address,
              coordinates: {
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng()
              }
            }
          }
        }
      )
    },
    setLocationByPlaceId(placeId) {
      // eslint-disable-next-line
      const geocoder = new google.maps.Geocoder()

      geocoder.geocode({ placeId }, (results, status) => {
        if (
          // eslint-disable-next-line
          status === google.maps.places.PlacesServiceStatus.OK &&
          results[0]
        ) {
          this.location = {
            address: results[0].formatted_address,
            coordinates: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            }
          }
        }
      })
    },
    onChangeDealerId(value) {
      this.invalid.dealerId = false
      this.$emit('update:dealerId', value)
    }
  }
}
</script>
