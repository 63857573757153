<template>
  <div class="am-fp-personal-data">
    <div>Persönliche Angaben</div>

    <amui-select
      label="Anrede (optional)"
      aria-label="Anrede (optional)"
      v-model="formData.salutation"
      :options="salutationOptions"
      :invalid="invalid.salutation"
      viewport="s"
      autocomplete="honorific-prefix"
      @change="onChangeSalutation"
    />

    <amui-input
      v-model="formData.firstName"
      label="Vorname"
      :invalid="invalid.firstName"
      viewport="s"
      autocomplete="given-name"
      @input="onInputFirstName"
    />

    <amui-input
      v-model="formData.lastName"
      label="Nachname"
      :invalid="invalid.lastName"
      viewport="s"
      autocomplete="family-name"
      @input="onInputLastName"
    />

    <amui-input
      v-model="formData.birthdate"
      label="Geburtsdatum (optional)"
      :invalid="invalid.birthdate"
      viewport="s"
      type="date"
      autocomplete="bday"
      @input="onInputBirthday"
    />

    <amui-input
      v-model="formData.zipCode"
      :label="$t('checkout.personalData.zipCode.label')"
      :invalid="invalid.zipCode"
      viewport="s"
      autocomplete="postal-code"
      @keydown="onKeyPressZipCode"
      @paste="onPasteZipCode"
      @input="onInputZipCode"
    />

    <amui-input
      v-model="formData.email"
      label="E-Mail Adresse"
      :invalid="invalid.email"
      viewport="s"
      autocomplete="email"
      @input="onInputEmail"
    />

    <amui-input
      v-model="formData.phone"
      label="Telefon"
      :invalid="invalid.phone"
      viewport="s"
      autocomplete="tel"
      @keydown="onKeyPressPhone"
      @paste="onPastePhone"
      @input="onInputPhone"
    />
  </div>
</template>
<script>
import { AmuiSelect } from '@/../ui/components/select'
import { AmuiInput } from '@/../ui/components/input'
import {
  onPasteZipCode,
  onKeyPressZipCode,
  onPastePhone,
  onKeyPressPhone,
  validateEmail,
  isValideZipcode
} from '@/app/utils/form-helper.js'

export default {
  components: {
    AmuiSelect,
    AmuiInput
  },
  props: {
    salutation: {
      type: String,
      required: false,
      default: null
    },
    firstName: {
      type: String,
      required: false,
      default: ''
    },
    lastName: {
      type: String,
      required: false,
      default: ''
    },
    birthdate: {
      type: String,
      required: false,
      default: ''
    },
    zipCode: {
      type: String,
      required: false,
      default: ''
    },
    email: {
      type: String,
      required: false,
      default: ''
    },
    phone: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      formData: {
        salutation: this.salutation,
        firstName: this.firstName,
        lastName: this.lastName,
        birthdate: this.birthdate,
        zipCode: this.zipCode,
        email: this.email,
        phone: this.phone
      },
      invalid: {
        salutation: false,
        firstName: false,
        lastName: false,
        birthdate: false,
        zipCode: false,
        email: false,
        phone: false
      },
      salutationOptions: [
        {
          label: 'Frau',
          value: 'FEMALE'
        },
        {
          label: 'Herr',
          value: 'MALE'
        }
      ]
    }
  },

  watch: {
    salutation(value) {
      this.formData.salutation = value
    },
    firstName(value) {
      this.formData.firstName = value
    },
    lastName(value) {
      this.formData.lastName = value
    },
    birthdate(value) {
      this.formData.birthdate = value
    },
    zipCode(value) {
      this.formData.zipCode = value
    },
    email(value) {
      this.formData.email = value
    },
    phone(value) {
      this.formData.phone = value
    }
  },

  methods: {
    onChangeSalutation(value) {
      this.invalid.salutation = false
      this.$emit('update:salutation', value)
    },
    onInputFirstName(value) {
      this.invalid.firstName = false
      this.$emit('update:firstName', value)
    },
    onInputLastName(value) {
      this.invalid.lastName = false
      this.$emit('update:lastName', value)
    },
    onInputBirthday(value) {
      this.invalid.birthdate = false
      this.$emit('update:birthdate', value)
    },
    onInputZipCode(value) {
      this.invalid.zipCode = false
      this.$emit('update:zipCode', value)
    },
    onInputEmail(value) {
      this.invalid.email = false
      this.$emit('update:email', value)
    },
    onInputPhone(value) {
      this.invalid.phone = false
      this.$emit('update:phone', value)
    },
    validateEmail: validateEmail,
    isValid() {
      this.invalid.firstName = this.formData.firstName.length === 0
      this.invalid.lastName = this.formData.lastName.length === 0
      this.invalid.zipCode = !isValideZipcode(this.formData.zipCode)
      this.invalid.email =
        this.formData.email.length === 0 ||
        !this.validateEmail(this.formData.email)
      this.invalid.phone = this.formData.phone.length === 0

      return !Object.keys(this.invalid).some(key => this.invalid[key])
    },
    validate() {
      return {
        valid: this.isValid()
      }
    },
    onPasteZipCode: onPasteZipCode,
    onKeyPressZipCode: onKeyPressZipCode,
    onPastePhone: onPastePhone,
    onKeyPressPhone: onKeyPressPhone
  }
}
</script>
