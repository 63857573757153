<template>
  <div class="am-fp-vehicle-model-selection">
    <div>Modellauswahl</div>
    <amui-select
      v-model="formData.series"
      :options="seriesOptions"
      label="Serie"
      aria-label="Serie"
      :invalid="invalid.series"
      :disabled="seriesOptions.length === 0"
      viewport="s"
      @change="onChangeSeries"
    >
    </amui-select>

    <amui-select
      v-model="formData.modelRange"
      :options="modelRangeOptions"
      label="Baureihe"
      aria-label="Baureihe"
      :invalid="invalid.modelRange"
      :disabled="formData.series === null"
      viewport="s"
      @change="onChangeModelRange"
    >
    </amui-select>

    <amui-select
      v-model="formData.modelCode"
      :options="modelCodeOptions"
      label="Modell"
      aria-label="Modell"
      :invalid="invalid.modelCode"
      :disabled="formData.modelRange === null"
      viewport="s"
      @change="onChangeModelCode"
    >
    </amui-select>
  </div>
</template>
<script>
import { AmuiSelect } from '@/../ui/components/select'

export default {
  components: {
    AmuiSelect
  },
  props: {
    series: {
      type: String,
      required: false,
      default: null
    },
    seriesOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    modelRange: {
      type: String,
      required: false,
      default: null
    },
    modelRangeOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    modelCode: {
      type: String,
      required: false,
      default: null
    },
    modelCodeOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      formData: {
        series: this.series,
        modelRange: this.modelRange,
        modelCode: this.modelCode
      },
      invalid: {
        series: false,
        modelRange: false,
        modelCode: false
      }
    }
  },
  watch: {
    series(value) {
      this.formData.series = value
    },
    modelRange(value) {
      this.formData.modelRange = value
    },
    modelCode(value) {
      this.formData.modelCode = value
    }
  },
  methods: {
    onChangeSeries(value) {
      this.invalid.series = false
      this.$emit('update:series', value)
    },
    onChangeModelRange(value) {
      this.invalid.modelRange = false
      this.$emit('update:modelRange', value)
    },
    onChangeModelCode(value) {
      this.invalid.modelCode = false
      this.$emit('update:modelCode', value)
    },
    isValid() {
      this.invalid.series = this.formData.series === null
      this.invalid.modelRange = this.formData.modelRange === null
      this.invalid.modelCode = this.formData.modelCode === null

      return !Object.keys(this.invalid).some(key => this.invalid[key])
    },
    validate() {
      return {
        valid: this.isValid()
      }
    }
  }
}
</script>
